import * as React from "react";

const SvgAbstract04Outlined = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.518 10.219 12 6.236l5.482 3.983-2.094 6.444H8.612L6.518 10.22Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export default SvgAbstract04Outlined;
