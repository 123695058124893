import * as React from "react";
import { Provider } from "react-redux";
import { DataProvider } from "./src/components/Database";
import GlobalMessages from "./src/components/GlobalMessages";
import store from "./src/store";

// eslint-disable-next-line react/display-name,react/prop-types
export function wrapWithProvider(element) {
  return (
    <Provider store={store}>
      <DataProvider>
        <GlobalMessages element={element} />
      </DataProvider>
    </Provider>
  );
}
