import * as React from "react";

const SvgChatBubbleRight = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 2h16c1.1 0 2 .9 2 2v18l-4-4H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2Zm0 14h14l2 2V4H4v12Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChatBubbleRight;
