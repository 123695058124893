import { useStaticQuery, graphql } from "gatsby";
import { filterLanguage, language } from "./_helpers";

export default function useMenuInterface(language: language | language[]) {
  const result = useStaticQuery(graphql`
    query UseMenuInterface {
      allInterface {
        edges {
          node {
            _language
            menuLanguage
            menuSettings
            menuItemAi
            menuItemUpdates
            noUpdateAvailableTitle
            noUpdateAvailableText
            noUpdateAvailableOk
          }
        }
      }
    }
  `);
  return filterLanguage(result, language || "UK");
}
