export const languages = [
  {
    locale: "UK",
    label: "українська",
  },
  {
    locale: "RU",
    label: "русский",
  },
  {
    locale: "DE",
    label: "Deutsch",
  },
  {
    locale: "EN",
    label: "English",
  },
];
