import * as React from "react";

const SvgChatBubbles = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 7H4c-1.1 0-2 .9-2 2v13l4-4h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2Zm0 9H6l-2 2V9h10v7Z"
      fill="currentColor"
    />
    <path
      d="M10 2h10c1.1 0 2 .9 2 2v13l-4-4h-8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2Zm0 9h8l2 2V4H10v7Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChatBubbles;
