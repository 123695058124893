import * as React from "react";

const SvgAbstract04 = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 5 6.657 4.837-2.543 7.826H7.886L5.344 9.837 12 5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAbstract04;
