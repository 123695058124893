import * as React from "react";

const SvgAbstract05Outlined = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.938 9.077 12 6.155l5.062 2.922v5.846L12 17.845l-5.062-2.922V9.077Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export default SvgAbstract05Outlined;
