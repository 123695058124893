import { nanoid } from "nanoid";
import {
  CHANGE_PRIMARY_LANGUAGE,
  SET_WELCOME_MESSAGE,
  SET_TRACKING_CONSENT,
} from "../actions";

const settingsReducer = (
  state = {
    primaryLanguage: "",
    welcomeMessageShown: false,
    trackingConsentGiven: undefined,
    clientId: null,
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_PRIMARY_LANGUAGE:
      return {
        ...state,
        primaryLanguage: action.payload.language,
      };
    case SET_WELCOME_MESSAGE:
      return {
        ...state,
        welcomeMessageShown: action.payload,
      };
    case SET_TRACKING_CONSENT:
      return {
        ...state,
        ...(action.payload &&
          state.clientId === null && { clientId: nanoid(32) }),
        trackingConsentGiven: action.payload,
      };
    default:
      break;
  }

  return state;
};

export default settingsReducer;
