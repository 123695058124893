import * as React from "react";
import tw, { styled } from "twin.macro";
import { motion, useAnimation } from "framer-motion";
import { Portal } from "@headlessui/react";
import Overlay from "./Overlay";

type LeftOrRightProps = { left?: boolean; right?: boolean };

const Anchor = styled.div(({ left, right }: LeftOrRightProps) => [
  tw`fixed z-30 top-0 w-0`,
  left && tw`left-0`,
  right && tw`right-0`,
]);

const Container = styled(motion.div)(({ width }: { width: number }) => [
  tw`relative z-10 bg-primary-container w-screen h-screen`,
  width && `max-width: ${width}px`,
]);

export function Drawer({
  width = 400,
  open = false,
  onClose,
  children,
}: {
  onClose?: () => void;
  overlayColor?: string;
  width?: number;
  open?: boolean;
  children?: React.ReactNode;
}) {
  const controls = useAnimation();

  React.useEffect(() => {
    console.log(open ? "Set open" : "close");
    controls.start(open ? "open" : "closed");
  }, [open, controls]);

  const containerStyles = {
    open: {
      x: 0,
    },
    closed: {
      x: -width,
    },
  };

  return (
    <Portal>
      <Anchor left={true}>
        {open && <Overlay onClick={onClose} />}
        <Container
          width={width}
          initial="closed"
          animate={controls}
          variants={containerStyles}
          transition={{ type: "spring", duration: 0.35, bounce: 0 }}
          drag="x"
          dragElastic={0}
          dragConstraints={{
            left: -width,
            right: 0,
          }}
          dragMomentum={false}
          onDragEnd={(_event, info) => {
            const isDraggingLeft = info.offset.x < 0;
            const multiplier = isDraggingLeft ? 1 / 3 : 2 / 3;
            const threshold = width * multiplier;

            console.log(isDraggingLeft, threshold);

            if (Math.abs(info.point.x) <= width - threshold && open) {
              console.log("CASE 1", info.point.x, threshold);
              if (onClose) {
                onClose();
              }
            } else {
              console.log("CASE 3");
              controls.start(open ? "open" : "closed");
            }
          }}
        >
          {children}
        </Container>
      </Anchor>
    </Portal>
  );
}

export default function useDrawer(
  options?: Partial<Pick<LeftOrRightProps, "right">>,
): {
  renderedDrawer: React.ReactNode;
  toggleDrawer: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
} {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const toggleDrawer = () => {
    setIsVisible((s) => !s);
  };
  const openDrawer = () => {
    setIsVisible(true);
  };
  const closeDrawer = () => {
    setIsVisible(false);
  };
  return {
    renderedDrawer: (
      <Drawer open={isVisible} onClose={closeDrawer} right={options?.right} />
    ),
    toggleDrawer,
    openDrawer,
    closeDrawer,
  };
}
