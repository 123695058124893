import tw, { styled } from "twin.macro";

export default styled.span(
  ({
    active = true,
    children,
  }: {
    active?: boolean;
    children?: React.ReactText;
  }) => [
    tw`inline-flex justify-center items-center text-label-sm text-default h-3 min-w-3 rounded-1.5 px-0.5`,
    active && tw`bg-primary text-on-primary`,
    !active && tw`bg-neutral`,
    !!children &&
      (typeof children === "number" ? children.toString() : children).length >
        1 &&
      tw`min-w-4`,
  ],
);
