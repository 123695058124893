import * as React from "react";
import Modal from "./Modal";
import useNotifications from "../hooks/useNotifications";
import { DataContext } from "../components/Database";
import {
  usePrimaryLanguage,
  useTracking,
  useWelcomeMessage,
} from "../hooks/settings";
import { isReady } from "../services/dbController";
import useModalInterface from "../hooks/useModalInterface";
import { languages as languagesConfig } from "../data/config";
import { abstractIcons, Item, ItemText } from "./Menu";
import "twin.macro";

export default function GlobalMessages({ element }) {
  const [welcomeMessageShown, setWelcomeMessageShown] = useWelcomeMessage();
  const { trackingConsentGiven, giveConsent, removeConsent } = useTracking();
  const [primaryLanguage, setPrimaryLanguage] = usePrimaryLanguage();
  const { lastUpdateCheck, updateAvailable, checkForUpdates } =
    useNotifications();
  const [showUpdateAvailable, setShowUpdateAvailable] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { db } = React.useContext(DataContext);
  const modalInterface = useModalInterface(primaryLanguage || "EN");

  React.useEffect(() => {
    if (updateAvailable && updateAvailable.length) {
      setShowUpdateAvailable(true);
    }
  }, [lastUpdateCheck, updateAvailable]);

  const handleClose = () => {
    setShowUpdateAvailable(false);
  };
  const handleUpdate = async () => {
    setIsUpdating(true);
    if (isReady(db)) {
      await db.update();
      checkForUpdates([]);
    }
    setIsUpdating(false);
    setShowUpdateAvailable(false);
  };

  const size = (updateAvailable || []).reduce((sum, { size }) => sum + size, 0);

  const setLanguage = (locale) => () => setPrimaryLanguage(locale);

  return (
    <React.Fragment>
      {/* Update Modal */}
      <Modal onClose={handleClose} open={showUpdateAvailable}>
        <Modal.Box>
          <Modal.Title>Update Available</Modal.Title>
          <Modal.ContentWrapper>
            <p>
              Do you want to download the update now?
              <br />
              It will need {size} KB of your data volume.
            </p>
          </Modal.ContentWrapper>
          <Modal.Actions>
            <Modal.Button onClick={handleClose} disabled={isUpdating}>
              Ask later
            </Modal.Button>
            <Modal.Button onClick={handleUpdate} disabled={isUpdating}>
              Install now
            </Modal.Button>
          </Modal.Actions>
        </Modal.Box>
      </Modal>
      {/* Welcome and choose language */}
      <Modal onClose={handleClose} open={!welcomeMessageShown}>
        <Modal.Box>
          <Modal.Title>{modalInterface.welcomeTitle}</Modal.Title>
          <Modal.ContentWrapper>
            <p>{modalInterface.welcomeText}</p>
          </Modal.ContentWrapper>
          <Modal.ContentWrapper tw="mt-3">
            {languagesConfig.map(({ locale, label }, index) => {
              const isActive = primaryLanguage === locale;
              const Icon =
                abstractIcons[index % abstractIcons.length][isActive ? 1 : 0];
              return (
                <Item
                  key={locale}
                  onClick={setLanguage(locale)}
                  active={primaryLanguage === locale}
                  tw="min-h-6"
                >
                  <Icon />
                  <ItemText>{label}</ItemText>
                </Item>
              );
            })}
          </Modal.ContentWrapper>
          <Modal.Actions>
            <Modal.Button
              onClick={setWelcomeMessageShown}
              disabled={!primaryLanguage}
            >
              {modalInterface.welcomeOk}
            </Modal.Button>
          </Modal.Actions>
        </Modal.Box>
      </Modal>
      {/* Set tracking */}
      <Modal
        onClose={handleClose}
        open={
          welcomeMessageShown && typeof trackingConsentGiven === "undefined"
        }
      >
        <Modal.Box>
          <Modal.Title>{modalInterface.trackingTitle}</Modal.Title>
          <Modal.ContentWrapper>
            <p>{modalInterface.trackingText}</p>
          </Modal.ContentWrapper>
          <Modal.Actions>
            <Modal.Button onClick={removeConsent}>
              {modalInterface.trackingNo}
            </Modal.Button>
            <Modal.Button onClick={giveConsent}>
              {modalInterface.trackingYes}
            </Modal.Button>
          </Modal.Actions>
        </Modal.Box>
      </Modal>
      {element}
    </React.Fragment>
  );
}
