import { useStaticQuery, graphql } from "gatsby";
import { filterLanguage, language } from "./_helpers";

export default function useModalInterface(language: language | language[]) {
  const result = useStaticQuery(graphql`
    query UseModalInterface {
      allInterface {
        edges {
          node {
            _language
            welcomeTitle
            welcomeText
            welcomeOk
            trackingTitle
            trackingText
            trackingYes
            trackingNo
            noUpdateAvailableTitle
            noUpdateAvailableText
            noUpdateAvailableOk
          }
        }
      }
    }
  `);
  return filterLanguage(result, language || "UK");
}
