import useNotifications from "../hooks/useNotifications.ts";

const React = require("react");

export const DataContext = React.createContext({});

export const DataProvider = ({ children }) => {
  const [database, setDatabase] = React.useState({});
  const { checkForUpdates } = useNotifications();

  React.useEffect(() => {
    let didCancel = false;
    async function loadDatabaseService() {
      const dbController = await import(
        /* webpackChunkName: "loki" */ "../services/dbController.js"
      );
      if (dbController && dbController.default && !didCancel) {
        const newDb = new dbController.default();
        setDatabase({ db: newDb });
        newDb.on("dataChange", () => {
          console.log("data changed");
          setDatabase(({ db }) => ({
            db,
          }));
        });
        newDb.on("updateAvailable", (packages) => {
          console.log("updateAvailable", packages, newDb.availablePackages);
          checkForUpdates(packages);
        });

        newDb.checkForUpdates();
      }
    }
    loadDatabaseService();
    return () => {
      didCancel = true;
    };
  }, []);
  return (
    <DataContext.Provider value={database}>{children}</DataContext.Provider>
  );
};
