import { compare } from "compare-versions";
import Loki from "lokijs";
import LokiIndexedAdapter from "lokijs/src/loki-indexed-adapter.js";

export const config = {
  adapterName: "dataAdapter",
  dbName: "data",
  pathToVersionFile: "/data/packages.json",
  appCollection: "_app",
};

export const INIT_STATE = 0;
export const BUSY_STATE = 1;
export const READY_STATE = 2;

export const EVENT_DATA_CHANGE = "dataChange";
export const EVENT_UPDATE_AVAILABLE = "updateAvailable";
export const EVENT_INITIAL_DOWNLOAD = "initialDownload";

export default class DbController {
  state = INIT_STATE;
  #adapter = new LokiIndexedAdapter(config.adapterName);
  #loki = null;
  #trigger = function (event, data) {
    const callbacks = this.callbacks[event];
    // const value = event === EVENT_DATA_CHANGE ? this.data : event === EVENT_UPDATE_AVAILABLE ? "LIST OF UPDATES" : null
    if (callbacks) {
      if (typeof data !== "undefined") {
        for (let i = 0, len = callbacks.length; i < len; i++) {
          callbacks[i](data);
        }
      } else {
        for (let i = 0, len = callbacks.length; i < len; i++) {
          callbacks[i]();
        }
      }
    }
  };
  #collections = {};
  constructor() {
    // this.data = {};
    this.callbacks = {};
    this.availablePackages = [];
    this.getCollection = this.getCollection.bind(this);
    this.update = this.update.bind(this);
    this.#loki = new Loki(config.dbName, {
      adapter: this.#adapter,
      autoload: true,
      autoloadCallback: this.databaseInitialize.bind(this),
      autosave: true,
    });
  }
  async databaseInitialize() {
    [
      config.appCollection,
      "starter",
      "category",
      "sentence",
      "conversation",
    ].forEach((collectionName) => {
      let collection = this.#loki.getCollection(collectionName);
      if (!collection) {
        collection = this.#loki.addCollection(collectionName, {
          unique: ["id"],
        });
        console.log(`"${collectionName}" added to database`);
      }
      this.#collections[collectionName] = collection;
    });
    this.state = READY_STATE;
    setTimeout(() => this.#trigger(EVENT_DATA_CHANGE));
    this.checkForUpdates();
  }

  _pushCallback(event, func) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event].push(func);
  }

  async getPackageList() {
    this.state = BUSY_STATE;
    try {
      const response = await fetch(config.pathToVersionFile);
      return await response.json();
    } catch (error) {
      return {
        error,
      };
    } finally {
      this.state = READY_STATE;
    }
  }

  async loadPackage(name, path) {
    let result = null;
    this.state = BUSY_STATE;
    try {
      console.log(`Start download of "${name}" (${path})`);
      const response = await fetch(path);
      result = await response.json();
      console.log(`Download of "${name}" finished.`);
    } catch (error) {
      // handle error
      console.log(`Error: Could not download "${name}".`);
    } finally {
      this.state = READY_STATE;
    }

    if (result && result.collections) {
      result.collections.forEach((collectionResult) => {
        const { name: collectionName, data: collectionData } = collectionResult;
        const collection = this.#collections[collectionName];
        collection.clear();
        collection.insert(collectionData);
      });
      const appCollection = this.#collections[config.appCollection];
      const packageDoc = appCollection.findOne({ key: "packages" });
      if (packageDoc) {
        packageDoc.packages[result.name] = {
          version: result.version,
        };
      } else {
        appCollection.insert({
          key: "packages",
          packages: {
            [result.name]: {
              version: result.version,
            },
          },
        });
      }
    }
  }

  async update() {
    console.log("UPDATE", this.availablePackages);
    if (this.availablePackages && this.availablePackages.length) {
      await Promise.all(
        this.availablePackages.map(({ name, path }) =>
          this.loadPackage(name, path),
        ),
      );
      this.availablePackages = [];
      this.#trigger(EVENT_DATA_CHANGE);
    }
  }

  async checkForUpdates() {
    if (this.state < READY_STATE) {
      return {
        error: "Busy",
        message: "Try again later",
      };
    }
    const availablePackages = await this.getPackageList();
    if (availablePackages.error) {
      // TODO: handle error
      return false;
    }
    // compare packages against existing packages
    const availablePackagesList = Object.entries(availablePackages);
    const appCollection = this.#collections[config.appCollection];
    const currentPackages = appCollection.findOne({
      key: "packages",
    })?.packages;
    this.availablePackages = (
      currentPackages
        ? availablePackagesList.filter(
            ([name, props]) =>
              currentPackages[name]?.version &&
              compare(props.version, currentPackages[name].version, ">"),
          )
        : availablePackagesList
    ).map(([name, props]) => ({
      name,
      ...props,
    }));
    if (currentPackages) {
      if (this.availablePackages.length) {
        this.#trigger("updateAvailable", this.availablePackages);
      }
    } else {
      this.update();
    }
  }

  on(event, func) {
    if ([EVENT_DATA_CHANGE, EVENT_UPDATE_AVAILABLE]) {
      this._pushCallback(event, func);
    }
  }
  _onDataChange() {
    const callbacks = this.callbacks["dataChange"];
    if (callbacks) {
      for (let i = 0, len = callbacks.length; i < len; i++) {
        callbacks[i](this.#loki);
      }
    }
  }
  getCollection(name) {
    return this.#collections[name];
  }
}

export function isReady(db) {
  return (
    !!db &&
    typeof db.state !== "undefined" &&
    db.state > 0 &&
    !!db.getCollection
  );
}
