import { combineReducers } from "redux";
import settingsReducer from "./settings";
import notificationsReducer from "./notifications";

const rootReducers = combineReducers({
  settings: settingsReducer,
  notifications: notificationsReducer,
});

export default rootReducers;
