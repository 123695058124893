import * as React from "react";

const SvgAbstract02Outlined = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.741 17 12 7.985 17.259 17H6.741Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export default SvgAbstract02Outlined;
