import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";
import tw, { styled } from "twin.macro";
import Overlay from "./Overlay";

export interface ModalProps {
  children?: React.ReactNode;
  open?: boolean;
  onClose: () => void;
}

const Modal = ({ children, onClose, open = false }: ModalProps) => {
  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        onClose={onClose}
        tw="fixed inset-0 z-40 overflow-y-auto text-center"
      >
        <Overlay />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span tw="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        {children}
      </Dialog>
    </Transition>
  );
};

const Box = ({
  children,
  ...props
}: {
  children: React.ReactNode;
  props?: any;
}) => {
  return (
    <div
      tw="relative z-10 inline-block w-[calc(100vw - 48px)] max-w-[776px] my-8 text-left align-middle bg-surface text-on-surface overflow-hidden rounded-3.5"
      {...props}
    >
      {children}
    </div>
  );
};

Modal.Box = Box;

const Title = tw.div`px-3 pt-3 pb-2 text-headline-sm`;

Modal.Title = Title;

const ContentWrapper = tw.div`px-3`;

Modal.ContentWrapper = ContentWrapper;

const Actions = tw.div`p-3 flex justify-end space-x-1`;

Modal.Actions = Actions;

const Button = styled.button(({ disabled }) => [
  tw`p-3 text-label-lg px-1.5 py-[10px]`,
  disabled && tw`opacity-30`,
]);

Modal.Button = Button;

export default Modal;
