export const CHANGE_PRIMARY_LANGUAGE = "CHANGE_PRIMARY_LANGUAGE";

export function changePrimaryLanguage(payload) {
  return {
    type: CHANGE_PRIMARY_LANGUAGE,
    payload,
  };
}

export const SET_AVAILABLE_UPDATES = "SET_AVAILABLE_UPDATES";

export function setAvailableUpdates(payload) {
  return {
    type: SET_AVAILABLE_UPDATES,
    payload: {
      packages: payload,
      lastCheck: Date.now(),
    },
  };
}

export const SET_WELCOME_MESSAGE = "SET_WELCOME_MESSAGE";

export function setWelcomeMessage(payload) {
  return {
    type: SET_WELCOME_MESSAGE,
    payload: true,
  };
}

export const SET_TRACKING_CONSENT = "SET_TRACKING_CONSENT";

export function setTrackingConsent(payload) {
  return {
    type: SET_TRACKING_CONSENT,
    payload,
  };
}
