import { SET_AVAILABLE_UPDATES } from "../actions";

const notificationsReducer = (
  state = { updateAvailable: [], lastUpdateCheck: 0 },
  action,
) => {
  switch (action.type) {
    case SET_AVAILABLE_UPDATES:
      console.log("PAYLOAD", action.payload);
      return {
        ...state,
        updateAvailable: action.payload.packages,
        lastUpdateCheck: action.payload.lastCheck,
      };
    default:
      break;
  }

  return state;
};

export default notificationsReducer;
