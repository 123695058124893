import { useSelector, useDispatch } from "react-redux";
import { setAvailableUpdates } from "../actions";

export default function useNotifications() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state?.notifications);
  return {
    lastUpdateCheck: notifications?.lastUpdateCheck,
    updateAvailable: notifications?.updateAvailable,
    checkForUpdates: (packages: Array<any>) =>
      dispatch(setAvailableUpdates(packages)),
  };
}
