export enum language {
  DE = "DE",
  UK = "UK",
  RU = "RU",
  EN = "EN",
}

export function filterLanguage(
  queryResult: any,
  languageCode: language | language[],
) {
  if (!Array.isArray(languageCode)) {
    return queryResult?.allInterface?.edges?.find(
      (edge) => edge.node["_language"] === languageCode,
    )?.node;
  }
  return queryResult?.allInterface?.edges
    ?.filter((edge) => {
      return languageCode.includes(edge.node["_language"]);
    })
    .reduce((acc, edge) => {
      return {
        ...acc,
        [edge.node["_language"]]: edge.node,
      };
    }, {});
}
