import * as React from "react";
import { Drawer } from "./Drawer";
import tw, { styled } from "twin.macro";
import { usePrimaryLanguage, useTracking } from "../hooks/settings";
import { useApp } from "../data/data";
import { languages as languagesConfig } from "../data/config";
import {
  Abstract01,
  Abstract02,
  Abstract03,
  Abstract04,
  Abstract05,
  Abstract01Outlined,
  Abstract02Outlined,
  Abstract03Outlined,
  Abstract04Outlined,
  Abstract05Outlined,
  Info,
  ModelTraining,
  PhoneSettings,
} from "./icons";
import packageJson from "../../package.json";
import useNotifications from "../hooks/useNotifications";
import { DataContext } from "./Database";
import NumberPill from "./NumberPill";
import useMenuInterface from "../hooks/useMenuInterface";
import AlertModal from "./AlertModal";

const SectionDivider = tw.div`relative h-0 after:(block w-full content-[''] absolute left-0 top-[-0.5px] border-t-[1px] border-t-outline)`;
const Title = tw.div`p-2 text-title-md`;
const SectionHeadline = tw.div`px-2 py-[18px] text-label-lg`;
export const Item = styled.div(({ active }) => [
  tw`px-2 min-h-[56px] flex items-center text-label-lg rounded-3.5 space-x-1.5`,
  active && tw`bg-primary text-on-primary`,
]);
export const ItemText = tw.span`ml-1`;

export const abstractIcons = [
  [Abstract01Outlined, Abstract01],
  [Abstract02Outlined, Abstract02],
  [Abstract03Outlined, Abstract03],
  [Abstract04Outlined, Abstract04],
  [Abstract05Outlined, Abstract05],
];

export default function Menu({ open, onClose }) {
  const { updateAvailable } = useNotifications();
  const { db } = React.useContext(DataContext);
  const { unsetTracking } = useTracking();
  const [primaryLanguage, setPrimaryLanguage] = usePrimaryLanguage();
  const menuInterface = useMenuInterface(primaryLanguage);
  const [showUpdateAlert, setShowUpdateAlert] = React.useState(false);
  const { packages } = useApp();
  const setLanguage = (locale) => () => setPrimaryLanguage(locale);
  const update = () => {
    if (updateAvailable && !!updateAvailable.length) {
      onClose();
      db.checkForUpdates();
    } else {
      setShowUpdateAlert(true);
    }
  };
  const setTracking = () => {
    onClose();
    unsetTracking();
  };

  const hideUpdateAlert = () => {
    setShowUpdateAlert(false);
  };
  return (
    <React.Fragment>
      <AlertModal
        show={showUpdateAlert}
        title={menuInterface.noUpdateAvailableTitle}
        text={menuInterface.noUpdateAvailableText}
        button={menuInterface.noUpdateAvailableOk}
        onClose={hideUpdateAlert}
      />
      <Drawer open={open} onClose={onClose} width={300}>
        <div tw="p-1.5 flex flex-col h-full">
          <Title>TODO: App-Title</Title> {/** TODO: title */}
          <div tw="overflow-y-auto flex-grow">
            <SectionHeadline>{menuInterface.menuLanguage}</SectionHeadline>{" "}
            {/** TODO: translate */}
            {languagesConfig.map(({ locale, label }, index) => {
              const isActive = primaryLanguage === locale;
              const Icon =
                abstractIcons[index % abstractIcons.length][isActive ? 1 : 0];
              return (
                <Item
                  key={locale}
                  onClick={setLanguage(locale)}
                  active={primaryLanguage === locale}
                >
                  <Icon />
                  <ItemText>{label}</ItemText>
                </Item>
              );
            })}
            <SectionDivider />
            <SectionHeadline>{menuInterface.menuSettings}</SectionHeadline>
            <Item onClick={setTracking}>
              <ModelTraining />
              <ItemText>{menuInterface.menuItemAi}</ItemText>
            </Item>
            <Item onClick={update}>
              <PhoneSettings />
              <ItemText>{menuInterface.menuItemUpdates}</ItemText>
              {updateAvailable && !!updateAvailable.length && (
                <NumberPill tw="ml-1">{updateAvailable.length}</NumberPill>
              )}
            </Item>
          </div>
          <div>
            <Item tw="text-body-md font-normal opacity-50 items-start">
              <Info tw="mt-[-2px] opacity-80" />
              <ItemText>
                <p>App-Version {packageJson.version}</p>
                {!!packages && (
                  <div>
                    Packages:
                    {packages.map((p) => (
                      <div key={p.name}>
                        {`${p.name.charAt(0).toUpperCase()}${p.name.slice(1)} ${
                          p.version
                        }`}
                      </div>
                    ))}
                  </div>
                )}
              </ItemText>
            </Item>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
