import Modal from "./Modal";

export default function AlertModal({ title, text, button, show, onClose }) {
  return (
    <Modal onClose={onClose} open={show}>
      <Modal.Box>
        <Modal.Title>{title}</Modal.Title>
        <Modal.ContentWrapper>
          <p>{text}</p>
        </Modal.ContentWrapper>
        <Modal.Actions>
          <Modal.Button onClick={onClose}>{button}</Modal.Button>
        </Modal.Actions>
      </Modal.Box>
    </Modal>
  );
}
