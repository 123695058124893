import * as React from "react";
import { DataContext } from "../components/Database";
import { isReady, config } from "../services/dbController";

export function useDialogs() {}

export function useCategories() {
  const { db } = React.useContext(DataContext);
  if (isReady(db)) {
    const category = db.getCollection("category");
    if (category) {
      console.log("NEW");
      return category.find() || [];
    }
  }
  return [];
}
export function useApp() {
  const { db } = React.useContext(DataContext);
  if (isReady(db)) {
    const appData = db.getCollection(config.appCollection);
    if (appData) {
      const packageEntry = appData.findOne({ key: "packages" });
      return {
        packages: (packageEntry && packageEntry.packages
          ? Object.entries(packageEntry.packages)
          : []
        ).map((entry) => ({
          name: entry[0],
          version: entry[1]?.version,
        })),
      };
    }
  }
  return {};
}

export function useStarterWithSentences(starter) {
  const { db } = React.useContext(DataContext);
  if (!starter) {
    return null;
  }
  if (!isReady(db)) {
    return null;
  }
  const starterWithSentences = {
    ...starter,
  };
  const sentence = db.getCollection("sentence");
  const allIds = Array.from(
    starter.sentences
      .reduce((acc, cur) => {
        cur.forEach((id: number) => {
          acc.set(id, true);
        });
        return acc;
      }, new Map())
      .keys(),
  );
  const sentencesById = sentence
    .where(({ id }: { id: number }) => allIds.includes(id))
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    );

  starterWithSentences.sentences = starterWithSentences.sentences.map(
    (someSentences) => someSentences.map((id) => sentencesById[id]),
  );

  return starterWithSentences;
}

export function useStarters(filter = {}) {
  const { db } = React.useContext(DataContext);
  if (isReady(db)) {
    const starter = db.getCollection("starter");
    if (starter) {
      return starter.find(filter);
    }
  }
  return [];
}

export function useItems(where: () => void) {
  const { db } = React.useContext(DataContext);
  let result = [];
  if (isReady(db)) {
    const starter = db.getCollection("starter");
    const sentence = db.getCollection("sentence");
    if (starter) {
      if (where) {
        result.push(...starter.where(where));
      } else {
        result.push(...starter.find());
      }
    }
    if (sentence) {
      if (where) {
        result.push(...sentence.where(where));
      } else {
        result.push(...sentence.find());
      }
    }
  }
  return result;
}
