import * as React from "react";

const SvgAbstract01Outlined = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 12a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export default SvgAbstract01Outlined;
