import * as React from "react";

const SvgAbstract03 = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="currentColor" d="M6 6h12v12H6z" />
  </svg>
);

export default SvgAbstract03;
