import * as React from "react";

const SvgAbstract01 = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAbstract01;
