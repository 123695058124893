import { useSelector, useDispatch } from "react-redux";
import {
  changePrimaryLanguage,
  setWelcomeMessage,
  setTrackingConsent,
} from "../actions";

export function usePrimaryLanguage() {
  const dispatch = useDispatch();
  return [
    useSelector((state) => state?.settings?.primaryLanguage),
    (language: "UK" | "RU" | "DE" | "EN") =>
      dispatch(changePrimaryLanguage({ language })),
  ];
}

export function useWelcomeMessage() {
  const dispatch = useDispatch();
  return [
    useSelector((state) => state?.settings?.welcomeMessageShown),
    () => dispatch(setWelcomeMessage(true)),
  ];
}

export function useTracking() {
  const dispatch = useDispatch();
  const { clientId, trackingConsentGiven } = useSelector(
    (state) => state?.settings,
  );
  return {
    clientId,
    trackingConsentGiven,
    giveConsent() {
      dispatch(setTrackingConsent(true));
    },
    removeConsent() {
      dispatch(setTrackingConsent(false));
    },
    toggleConsent() {
      dispatch(setTrackingConsent(!trackingConsentGiven));
    },
    unsetTracking() {
      dispatch(setTrackingConsent(undefined));
    },
  };
}
