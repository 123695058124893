import * as React from "react";

const SvgAbstract05 = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m12 5 6.062 3.5v7L12 19l-6.062-3.5v-7L12 5Z" fill="currentColor" />
  </svg>
);

export default SvgAbstract05;
